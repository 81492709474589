.main-container {
  min-height: 100vh;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  width: 100%;
  height: 60px;
  padding-left: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  background: var(--color-shape);
  color: var(--color-white);
  border-bottom: 0.1px solid var(--color-borders);
}

.informPanel{
  margin: 5% auto;
  width: 75%;

  background-color: rgba(255,255,255,0.85);
  text-align: left;
  padding: 15px;
}
.title{
  color: darkred;
}
.linkAreaCliente{
  font-weight: bold;
}

.disconnectedImg{
  float: left;
  height: 350px;
  margin-right: 15px;
}

.logo{
  width: 50px;
  height: 50px;
}

.items{
    padding: 25px;
    padding-bottom: 70px;
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    list-style: none;
}

@media only screen and (max-width: 800px){
  .items{
    width: 90%;
    grid-template-columns: 1fr;
}
.main-container {
  align-items: center;
}
}

.footer {
  width: 100%;
  height: 70px;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  position:absolute;
  bottom: 0;
}

.footer img{
  margin: 0 5px;
  width: 40px;
  height: 40px;
}
li {
    background-color: var(--color-white);
    color: var(--color-shape);
    opacity: 1;
    min-height: 130px;
    border-radius: 4px;
    position: rel;
  
}

.item-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1px solid rgba(0,0,0,0.1);;
    padding: 20px;
}

.item-content{
    padding: 20px;
    color: var(--color-shape-hover);
}
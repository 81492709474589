:root {
  --color-background: #121214;
  --color-green: #23A455;
  --color-red: #e83f5b;
  --color-orange: #fd951f;
  --color-yellow: #f7df1e;
  --color-primary: #8257e6;
  --color-primary-hover: #9466ff;
  --color-secondary: #e1e1e6;
  --color-text: #a8a8b3;
  --color-support: #737380;
  --color-shape: #202024;
  --color-shape-hover: #29292e;
  --color-icons: #41414d;
  --color-borders: #323238;
  --color-black: #0d0d0f;
  --color-white: #f2f2f2;
}

*{
  margin:0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  user-select: none;
}

html, body, #root{
  min-height: 100%;
}

a{
  text-decoration: none;
}

li, img{
  cursor: pointer;
  transition: all .2s ease-in-out;
}

li:hover{
  transform: scale(1.1);
  opacity: 1;
  color: var(--color-green);
}

img:hover{
  transform: scale(1.1);
  opacity: 0.1;
}